import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import darkPdf from "../../Assets/Sumon Biswas Resume - Dark.pdf";
import lightPdf from "../../Assets/Sumon Biswas Resume - Light.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const [pdf, setPdf] = useState(darkPdf);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    checked ? setPdf(darkPdf) : setPdf(lightPdf);
  }, [checked]);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Form>
          <Form.Check
            type="switch"
            disabled={true}
            id="custom-switch"
            label={checked ? "Dark Mode" : "Light Mode"}
            defaultChecked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        </Form>
        <br></br>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download
          </Button>
        </Row>

        <Row className="resume">
          <Document file={pdf}>
            <Page pageNumber={1} scale={width > 786 ? 1.2 : 0.5} />
            {/* <Page pageNumber={2} scale={width > 786 ? 1.2 : 0.5} /> */}
          </Document>
        </Row>

        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row> */}
      </Container>
    </div>
  );
}

export default ResumeNew;

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  AiFillContacts,
  AiOutlineFundProjectionScreen,
  AiOutlineHome,
  AiOutlineProject,
  AiOutlineUser,
} from "react-icons/ai";
import { BiAward } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { Link } from "react-router-dom";
import logo from "../Assets/logo.png";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [cbtn, setCbtn] = useState("");
  const [page, setPage] = useState("");

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
      // console.log(page)
      if (window.location.href.split("/")[3] === "") {
        const x = window.scrollY;
        setPage();
        if (x < 1300) {
          setCbtn("home");
        } else if (x > 1300 && x < 4250) {
          setCbtn("about");
        } else if (x > 4250 && x < 5200) {
          setCbtn("experiences");
        } else if (x > 5200 && x < 7400) {
          setCbtn("projects");
        } else if (x > 7400 && x < 12500) {
          setCbtn("cf");
        } else if (x > 12500 && x < 13400) {
          setCbtn("cv");
        } else if (x > 13400) {
          setCbtn("ct");
        }
      }
    } else {
      updateNavbar(false);
    }
  }
  useState(() => {
    if (window.location.href.split("/")[3] === "") {
      setPage("home");
      setCbtn("home");
    }
  }, []);

  const gotoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          onClick={() => {
            updateExpanded(false);
            setPage("home");
            setCbtn("home");
            gotoTop();
          }}
        >
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey="#home">
            <Nav.Item
              className={cbtn === "home" && page === "home" ? "currentbtn" : ""}
            >
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => {
                  updateExpanded(false);
                  setPage("home");
                  setCbtn("home");
                  gotoTop();
                }}
              >
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              className={
                cbtn === "about" || page === "about" ? "currentbtn" : ""
              }
            >
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => {
                  updateExpanded(false);
                  setPage("about");
                  setCbtn("about");
                }}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item>
            <Nav.Item
              className={
                cbtn === "experiences" || page === "experiences"
                  ? "currentbtn"
                  : ""
              }
            >
              <Nav.Link
                as={Link}
                to="/experiences"
                onClick={() => {
                  updateExpanded(false);
                  setPage("experiences");
                  setCbtn("experiences");
                }}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                Experiences
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              className={
                cbtn === "projects" || page === "projects" ? "currentbtn" : ""
              }
            >
              <Nav.Link
                as={Link}
                to="/projects"
                onClick={() => {
                  updateExpanded(false);
                  setPage("projects");
                  setCbtn("projects");
                }}
              >
                <AiOutlineProject style={{ marginBottom: "2px" }} /> Projects
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              className={cbtn === "cf" || page === "cf" ? "currentbtn" : ""}
            >
              <Nav.Link
                as={Link}
                to="/certificates"
                onClick={() => {
                  updateExpanded(false);
                  setPage("cf");
                  setCbtn("cf");
                }}
              >
                <BiAward style={{ marginBottom: "2px" }} /> Certifications
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              className={cbtn === "cv" || page === "cv" ? "currentbtn" : ""}
            >
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => {
                  updateExpanded(false);
                  setPage("cv");
                  setCbtn("cv");
                }}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
              </Nav.Link>
            </Nav.Item>

            <Nav.Item
              className={cbtn === "ct" || page === "ct" ? "currentbtn" : ""}
            >
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => {
                  updateExpanded(false);
                  setPage("ct");
                  setCbtn("ct");
                }}
              >
                <AiFillContacts style={{ marginBottom: "2px" }} /> Contact
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item className={cbtn==="404" || page==="404" ? "currentbtn" : ''}>
              <Nav.Link
                as={Link}
                to="/404"
                onClick={() => {updateExpanded(false); setPage("404");setCbtn("404")}}
              >
                <BiError style={{ marginBottom: "2px" }} /> 404
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item className="fork-btn">
              <Button
                href="https://github.com/sumonbiswas2010"
                target="_blank"
                className="fork-btn-inner"
              >
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />
              </Button>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;

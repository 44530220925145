import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import Particle from "../Particle";

import Aos from "aos";

function Educations() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Aos.init({ once: true });
  }, []);
  const education = [
    {
      university: "Green University of Bangladesh",
      degree: "BSC in CSE",
      gpa: "3.53 out of 4",
      fromDate: "2018",
      toDate: "2021",
    },
    {
      university: "Government PC College, Bagerhat",
      degree: "Higher Secondary Certificate",
      gpa: "4.75 out of 5",
      fromDate: "2014",
      toDate: "2016",
    },
    {
      university: "Bagerhat Government High School",
      degree: "Secondary School Certificate",
      gpa: "5 out of 5",
      fromDate: "2009",
      toDate: "2014",
    },
  ];

  return (
    <Container fluid>
      <Particle />

      <Container>
        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="education"
        >
          <div className="w-100">
            <h2 className="mb-5">Education</h2>
            {education.map((data, index) => (
              <div
                key={index}
                className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5"
              >
                <div className="resume-content">
                  <h3 className="subheading mb-0">{data.university}</h3>
                  <div className="subheading mb-3">{data.degree}</div>
                  <div className="subheading mb-3">{data.gpa}</div>
                </div>
                <div className="resume-date text-md-right">
                  <span className="text-primary">
                    {data.fromDate} - {data.toDate}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </section>
      </Container>
    </Container>
  );
}

export default Educations;

import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Senior Software Engineer",
          "Android Application Developer",
          "Microservice Architect",
          "Fintech Engineer",
          "DevOps Architect",
        ],
        autoStart: true,
        loop: true,
        delay: 30,
        pauseFor: 500,
        deleteSpeed: 15,
      }}
    />
  );
}

export default Type;

import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import Particle from "../Particle";

import Aos from "aos";

function Extras() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Aos.init({ once: true });
  }, []);
  const education = [
    {
      university: "Debater & Trainer",
      degree: "Bagerhat Debater Club",
      fromDate: "2011",
      toDate: "2015",
    },
    {
      university: "Debater",
      degree: "Bangladesh Debating Society, Bagerhat",
      fromDate: "2010",
      toDate: "2014",
    },
    {
      university: "Member & Organizer",
      degree: "National Children's Task Force (NCTF)",
      fromDate: "2012",
      toDate: "2015",
    },
    {
      university: "CHILD JOURNALIST",
      degree: "BDNews24.com ",
      fromDate: "2009",
      toDate: "2014",
    },
    {
      university: "Publicity Secretary & Organizer",
      degree: "Prothom-Alo Bondhushava",
      fromDate: "2011",
      toDate: "2017",
    },
  ];

  return (
    <Container fluid>
      <Particle />

      <Container>
        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="education"
        >
          <div className="w-100">
            <h2 className="mb-5">Some Extracurricular Activities</h2>
            {education.map((data, index) => (
              <div
                data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                key={index}
                className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5"
              >
                <div className="resume-content">
                  <h3 className="subheading mb-0">{data.university}</h3>
                  <div className="subheading mb-3">{data.degree}</div>
                  <div className="subheading mb-3">{data.gpa}</div>
                </div>
                <div className="resume-date text-md-right">
                  <span className="text-primary">
                    {data.fromDate} - {data.toDate}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </section>
      </Container>
    </Container>
  );
}

export default Extras;

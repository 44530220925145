import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import GitHubCalendar from "react-github-calendar";
import ReactTooltip from "react-tooltip";
function Github() {
  const colourTheme = {
    background: "transparent",
    text: "#ffffff",
    level4: "#8400b8",
    level3: "#b22ff4",
    level2: "#b265f6",
    level1: "#c084f5",
    level0: "#ecd9fc",
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
    Aos.init({ once: true });
  }, []);
  return (
    <Row
      data-aos="fade-up"
      style={{ justifyContent: "center", paddingBottom: "10px" }}
    >
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        Days I <strong className="purple">Code</strong>
      </h1>
      <GitHubCalendar
        username="sumonbiswas2010"
        blockSize={15}
        blockMargin={5}
        theme={colourTheme}
        fontSize={16}
        // eslint-disable-next-line react/no-children-prop
        children={<ReactTooltip html />}
        labels={{
          legend: {
            less: "Less",
            more: "More",
          },
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          tooltip: "<strong>{{count}} contributions</strong> on {{date}}",
          totalCount: "{{count}} contributions in {{year}}",
          weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        }}
      />
    </Row>
  );
}

export default Github;

// here is some error, I guess.
// I have added all the babel plugins I find to add. Still cannot resolve the issue.!!

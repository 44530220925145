/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiAndroid,
  SiFirebase,
  SiHeroku,
  SiJira,
  SiLinux,
  SiPostman,
  SiVercel,
  SiVisualstudiocode,
} from "react-icons/si";

import { DiDocker, DiGit, DiGithubBadge } from "react-icons/di";
import { FaAws } from "react-icons/fa";

import { AiFillAndroid } from "react-icons/ai";
import { CgWindows } from "react-icons/cg";
// notepad
import Aos from "aos";
import "aos/dist/aos.css";
function Toolstack() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Row
      data-aos="fade-right"
      style={{ justifyContent: "center", paddingBottom: "50px" }}
    >
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <p className="techName">VS Code</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGithubBadge />
        <p className="techName">GitHub</p>
      </Col>
      {/* <Col xs={4} md={2} className="tech-icons">
        <SiSelenium/>
        <p className="techName">Selenium</p>
      </Col> */}

      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
        <p className="techName">Linux OS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <CgWindows />
        <p className="techName">Windows OS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <AiFillAndroid />
        <p className="techName">Android OS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p className="techName">Git</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <p className="techName">Google Firebase</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaAws />
        <p className="techName">AWS</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
        <p className="techName">Docker</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJira />
        <p className="techName">Jira</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAndroid />
        <p className="techName">Android Studio</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel />
        <p className="techName">Vercel</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <p className="techName">Postman</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku />
        <p className="techName">Heroku</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
